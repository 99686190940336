<template>
  <div class="user-list">
    <div class="title">
      <span class="s">用户列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户名称：">
          <el-input
            v-model="formInline.username"
            size="mini"
            placeholder="用户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select size="mini" v-model="formInline.status" placeholder="全部">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学校：">
          <el-select size="mini" v-model="formInline.school" placeholder="全部">
            <el-option label="全部" value="0"></el-option>
            <el-option
              :label="item.school_name"
              :value="item.id"
              v-for="item in schoolList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getUserList();
            "
            >查询</el-button
          >
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="realname"
          label="用户名"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="username"
          label="登录账号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="创建时间"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.time | formatTimeS("all")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        align="center"
        prop="last_time"
        label="最近登录时间"
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.last_time | formatTimeS("all")
          }}</span>
        </template>
      </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="角色"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.role_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="启用状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.state == 0 ? "禁用" : "启用" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="操作"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.id != 1" class="btn" @click="edit(scope.row)"
              >编辑</span
            >
            <!-- <span class="btn" @click="handleDel">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        :page-size="page_size"
        layout="prev, pager, next"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { userList, getSchoolList } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        username: "",
        status: "",
        school: "",
      },
      page: 1,
      page_size: 10,
      tableData: {},
      schoolList: {},
      loading: true,
    };
  },
  created() {
    this.getUserList();
    this.getXXList();
  },
  methods: {
    getUserList() {
      this.loading = true;
      userList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    getXXList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    handleReset() {
      this.formInline = {
        username: "",
        status: "",
        school: "",
      };
      this.page = 1;
      this.getUserList();
    },
    edit(val) {
      sessionStorage.ADMIN_USER_INFO = JSON.stringify(val);
      this.$router.push("editUser");
    },
    pageChange(index) {
      this.page = index;
      this.getUserList();
    },
  },
};
</script>
<style lang="less">
.user-list {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-table--border .el-table__cell {
  border-color: #000;
}
</style>